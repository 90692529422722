import styled, { keyframes, css } from "styled-components"
import { pallete } from 'services/theme'

const pulseTop = keyframes`
  0% { background-color: ${pallete.blue.main}; }
  33% { background-color: ${pallete.yellow.main}; }
  66% { background-color: ${pallete.pink.main }; }
  100% { background-color: ${pallete.blue.main}; }
`

const pulseBottom = keyframes`
  0% { background-color: ${pallete.pink.main }; }
  33% { background-color: ${pallete.yellow.main}; }
  66% { background-color: ${pallete.blue.main}; }
  100% { background-color: ${pallete.pink.main}; }
`

const animationTop = () => css`${pulseTop} 60s infinite;`
const animationBottom = () => css`${pulseBottom} 60s infinite;`

const Split = styled.div`
  min-height: 50%;
  height: 50%;
  display: flex;
  animation: ${({ variant }) => variant === 'top' ? animationTop : animationBottom};
`

export default Split

import React from "react"
import styled from "styled-components"
import SEO from 'components/SEO'
import CssBaseline from 'components/CssBaseline'
import Background from 'components/home/Background'
import SplitContainer from 'components/home/SplitContainer'
import { Link, Anchor } from 'components/Core'

const Container = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`

const Jumbo = styled.div`
  z-index: 3;
  position: relative;
  margin: auto;
`

const linkMixin = `
  font-size: calc(20px + 15vw);        
  color: white;
  &:hover {
    color: black;
  }
`

const PortfolioLink = styled(Link)`${linkMixin}`

const ShopLink = styled(Anchor)`${linkMixin}`

export default function Index() {
  return (
    <CssBaseline>
      <SEO title="HOME" />
      <Container>
        <SplitContainer variant="top">
          <Jumbo>
            <PortfolioLink
              to="/portfolio"
            >
              PORTFOLIO
            </PortfolioLink>
          </Jumbo>
        </SplitContainer>
        <SplitContainer variant="bottom">
          <Jumbo>
            <ShopLink
              href="http://ashleyhaydesign.com" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              SHOP
            </ShopLink>
          </Jumbo>
        </SplitContainer>
        <Background />
      </Container>
    </CssBaseline>
  )
}

import styled from 'styled-components'
import backgroundUrl from 'img/home-bg-pattern.svg'

const Background = styled.div`
  background-image: url(${backgroundUrl});
  background-size: cover;
  top:0;
  left: 0;
  height: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
`

export default Background
